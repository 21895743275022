//Components

//Pages
@import "./general";
@import "./fonts";
@import "./login";

body,
* {
  margin: 0;
  padding: 0;
  font-family: "SFProText";
  font-weight: normal;
  box-sizing: border-box;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}

//custom scrollbar
::-webkit-scrollbar {
  width: 7px;
  height: 5px; /* width of the entire scrollbar */
}

::-webkit-scrollbar-track {
  background: #e6e6e6; /* color of the tracking area */
}

::-webkit-scrollbar-thumb {
  background-color: #cccccc; /* color of the scroll thumb */
  border-radius: 20px;
}
