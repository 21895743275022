::-ms-reveal {
  display: none;
}

.MuiChip-root {
  &.MuiChip-outlinedSuccess {
    background-color: #e1fbf2;
    border: 1px solid #bfded3;
    padding-bottom: 2px;

    .MuiChip-label {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #0f875b;
    }

    &.Mui-disabled {
      background-color: rgba(0, 0, 0, 0.16);

      .MuiChip-label {
        color: #444444;
      }
    }
  }

  &.MuiChip-colorError {
    .MuiChip-label {
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #f16149;
    }

    &.MuiChip-outlinedError {
      background-color: #ffe7e3;
    }
  }
}

.expanded-icon {
  transform: rotate(90deg);
}

.action-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  transform-origin: 0% 50%;

  img {
    max-width: 20px;
    max-height: 20px;
    cursor: pointer;

    &:hover {
      background-color: #e6e6e6;
      border-radius: 50%;
      outline: 5px solid #e6e6e6;
    }

    &.disabled-img {
      filter: invert(85%) sepia(0%) saturate(521%) hue-rotate(137deg)
        brightness(96%) contrast(97%);

      &:hover {
        background-color: transparent;
        border-radius: 50%;
        outline: none;
      }
    }
  }
}

.img-disabled {
  opacity: 0.3;
}

.custom-checkbox-root {
  .MuiSvgIcon-root {
    width: 18px;
    height: 18px;
  }
}

.MuiTablePagination-actions {
  &:not(.Mui-disabled) {
    .MuiSvgIcon-root {
      &:hover {
        background-color: #e6e6e6;
        border-radius: 50%;
      }
    }
  }
}

//Loader
.custom-loader-wrapper {
  display: flex;
  position: relative;
  justify-content: center;

  .custom-loader {
    width: 48px;
    height: 48px;
    border: 5px solid #e6e6e6;
    border-bottom-color: #0157de;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

//toastify
.toastify {
  border-radius: 8px !important;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.43;
  letter-spacing: 0.01071em;
  min-width: max-content;
  margin-left: auto;
  display: flex !important;
  align-items: center;
  padding: 8px 16px !important;
  padding-right: 40px !important;

  .toastify-avatar {
    filter: invert(100%) sepia(0%) saturate(580%) hue-rotate(186deg)
      brightness(106%) contrast(101%);
    margin: 0px 8px 0 0;
    height: 22px;
    width: 22px;
  }

  .toast-close {
    position: absolute;
    right: 12px;
    top: 9px;
  }
}

.img-16 {
  width: 16px;
  height: 16px;
}

.img-24 {
  width: 24px;
  height: 24px;
}

//React date range picker

.calendarWrap {
  display: inline-block;
  position: relative;
  .calendarElement {
    position: absolute;
    left: 50%;
    transform: translateX(-61%);
    top: 40px;
    border: none;
    z-index: 999;
    box-shadow: 0px 1px 45px rgb(0 0 0 / 16%);
    border-radius: 8px;

    .rdrCalendarWrapper {
      border-radius: 8px;
      .rdrMonthAndYearWrapper {
        padding-top: 0;
      }
    }
    .rdrDefinedRangesWrapper {
      display: none;
    }
  }
}


.font-size{
  display: flex;
  flex-direction: row !important;
}