// Mixins
@mixin transition($transition) {
    -moz-transition: $transition;
    -o-transition: $transition;
    -webkit-transition: $transition;
    transition: $transition;
  }
  
  .loader {
    position: absolute;
    width: 100%;
    height: 100vh;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }
  .landingWrap {
    display: flex;
    align-items: center;
    padding: 2% 0;
    min-height: 100vh;
    .sideStep {
      width: 50%;
      padding: 4% 6%;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;
  
      h1 {
        font-size: 4vw;
        font-weight: 700;
        color: #1f529d;
        margin-bottom: 2vw;
      }
      p {
        font-size: 1.5vw;
        line-height: 2vw;
        font-weight: 400;
        color: #656565;
        margin-bottom: 2.4vw;
      }
      .step {
        font-size: 1.2vw;
        line-height: 1.6vw;
        font-weight: 400;
        color: #000000;
        margin-bottom: 1.5vw;
        display: flex;
        gap: 1vw;
        align-items: center;
        span {
          width: 1.8vw;
          height: 1.8vw;
          font-size: 1vw;
          background: #21e09d;
          border-radius: 1.2vw;
          display: flex;
          font-weight: 500;
          align-items: center;
          justify-content: center;
          -webkit-border-radius: 1.8vw;
        }
      }
      &.rightBorder {
        position: relative;
        &:after {
          width: 0.3vw;
          position: absolute;
          content: "";
          right: 0;
          top: 0;
          height: 100%;
          background: linear-gradient(
            180deg,
            rgba(209, 229, 244, 0) 0%,
            #d1e5f4 53.12%,
            rgba(209, 229, 244, 0) 100%
          );
        }
      }
    }
    .bfitBag {
      width: 6vw;
      margin: 0 auto 2vw;
      img {
        width: 100%;
        height: auto;
      }
      .light {
        display: block;
      }
      .dark {
        display: none;
      }
    }
    .bfittext {
      font-size: 1vw;
      line-height: 1.2vw;
      font-weight: 600;
      color: #1f529d;
      text-align: center;
      width: 100%;
      margin-bottom: 2vw;
    }
    .inputlabel {
      font-size: 1.4vw;
      line-height: 2vw;
      font-weight: 400;
      color: #000000;
      margin-bottom: 1.5vw;
      text-align: center;
      width: 100%;
    }
    .version {
      font-size: 1vw;
      line-height: 2vw;
      font-weight: 400;
      color: #000000;
      margin-top: 1vw;
      text-align: center;
      width: 100%;
    }
    .textInput {
      padding: 1vw 2vw;
      height: 5vw;
      width: 100%;
      background: #f2f2f2;
      border: 1px solid #cccccc;
      outline: none;
      font-size: 1.4vw;
      color: #000000;
      border-radius: 6vw;
      -webkit-border-radius: 0.7vw;
      margin-bottom: 1vw;
    }
    .btn {
      padding: 0;
      height: 5vw;
      width: 100%;
      background: #1f529d;
      border: none;
      outline: none;
      font-size: 1.4vw;
      text-align: center;
      cursor: pointer;
      color: #fff;
      font-weight: 500;
      border-radius: 0.7vw;
      -webkit-border-radius: 0.7vw;
      filter: saturate(1);
      @include transition(all 0.3s ease);
      &:hover {
        filter: saturate(1.5);
      }
      &:disabled {
        opacity: 0.4;
        cursor: default;
      }
    }
  }
  
  .screenWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
    height: auto;
  }
  
  .screenHead {
    display: flex;
    align-items: center;
    padding: 15px 25px;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    .logo-wrap {
      margin: 0;
      display: flex;
      align-items: center;
      img {
        width: 52px;
        height: auto;
      }
      .store-name {
        margin-left: 24px;
        font-size: 24px;
        line-height: 2vw;
        font-weight: 500;
      }
    }
    .screenName {
      font-size: 20px;
      line-height: 2vw;
      font-weight: 400;
      color: #1f529d;
    }
  }
  .screenNumbers {
    padding: 3% 6% 4%;
    display: flex;
    gap: 2vw;
    flex-wrap: wrap;
    .screenNumber {
      height: 7.5vw;
      // width: 18%;
      display: flex;
      padding: 1vw;
      align-items: center;
      justify-content: center;
      background: #f1f1f1;
      border: 1px solid #dcdcdc;
      border-radius: 1vw;
      font-size: 2vw;
      font-weight: 700;
      word-break: break-all;
      text-align: center;
    }
  }
  
  footer {
    padding: 15px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    background: #f1f4f9;
    height: 55px;
    .footerLogo {
      position: relative;
      width: auto;
      margin: 0;
      font-size: 1vw;
      font-weight: 600;
      color: #1f529d;
      img {
        width: 42px;
        height: auto;
        position: absolute;
        bottom: 100%;
        margin: auto 0 0 20px;
        margin-bottom: 0.5vw;
        border-radius: 0;
      }
      .dark {
        display: none;
      }
    }
    .footertext {
      font-weight: 600;
      font-size: 1vw;
    }
  }
  
  .orderWrap {
    display: flex;
    overflow: hidden;
    .orderStatus,
    .orderAd {
      display: flex;
      height: calc(100vh - 138px);
    }
    .orderStatus {
      display: flex;
      width: 100%;
    }
    .orderAd {
      align-items: center;
      justify-content: center;
      overflow: hidden;
      background: #f0f0f0;
      padding: 2%;
      width: 50%;
      min-width: 50%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        display: block;
      }
    }
    .preparHead {
      padding: 2% 6%;
      background: #fdcd87;
      font-size: 1.5vw;
      font-weight: 700;
      color: #303030;
      display: flex;
      align-items: center;
      gap: 1vw;
    }
    .readyHead {
      padding: 2% 6%;
      background: #005846;
      font-size: 1.5vw;
      font-weight: 700;
      color: #fff;
      display: flex;
      align-items: center;
      gap: 1vw;
      margin-left: -1px;
      margin-right: -1px;
    }
  }
  .onProgress {
    width: 50%;
    .counts {
      padding: 20px;
      display: flex;
      gap: 18px;
      flex-wrap: wrap;
      height: 100%;
      align-content: baseline;
      span {
        height: 54px;
        width: 100%;
        background: #fdcd87;
        font-size: 1.7vw;
        font-weight: 700;
        color: #303030;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 6% 0;
      }
    }
  }
  .readyCollect {
    width: 50%;
    border-left: 1px solid #77967c;
    .counts {
      padding: 20px;
      display: flex;
      gap: 18px;
      flex-wrap: wrap;
      height: 100%;
      align-content: baseline;
      span {
        height: 54px;
        width: 100%;
        background: #005846;
        font-size: 1.7vw;
        font-weight: 700;
        color: #fff;
        display: flex;
        padding: 6% 0;
        align-items: center;
        justify-content: center;
      }
    }
  }
  
  .withOutAd {
    .readyCollect,
    .onProgress {
      .counts {
        span {
          width: calc(50% - 10px);
          padding: 5% 0;
          @media screen and (min-width: 1400px) {
            width: calc(50% - 10px);
            font-size: 1.5vw;
          }
        }
      }
    }
  }
  
  // Dark theme style start here
  
  .dark {
    background: #32363c;
    .sideStep {
      h1 {
        color: #ffffff;
      }
      p {
        color: #cccdcf;
      }
      .step {
        color: #ffffff;
        span {
          color: #32363c;
        }
      }
      &.rightBorder {
        &:after {
          background: linear-gradient(
            180deg,
            rgba(73, 73, 73, 0) 0%,
            #6c6c6c 49.48%,
            rgba(73, 73, 73, 0) 100%
          );
        }
      }
      .bfittext {
        color: #ffffff;
      }
      .bfitBag {
        .light {
          display: none;
        }
        .dark {
          display: block;
        }
      }
      .inputlabel {
        color: #ffffff;
      }
      .textInput {
        background: rgba(0, 0, 0, 0.36);
        border: rgba(0, 0, 0, 0.36);
        color: #8e8e93;
      }
      .btn {
        background: #21e09d;
        color: #32363c;
        &:disabled {
          opacity: 0.4;
          cursor: default;
        }
      }
    }
    .orderAd {
      background: #4a4d53;
    }
    .screenHead {
      border-bottom: 1px solid #494949;
      .screenName {
        color: #21e09d;
      }
      .store-name {
        color: #e6e6e6;
      }
    }
    .screenNumber {
      background: rgba(0, 0, 0, 0.2);
      border: 1px solid #676767;
      color: #e6e6e6;
    }
    footer {
      background: #40454d;
      color: #ffffff;
      .footerLogo {
        color: #ffffff;
        .light {
          display: none;
        }
        .dark {
          display: block;
        }
      }
    }
    .orderStatus {
      .onProgress {
        .preparHead {
          background: #ffd79b;
        }
        .counts {
          span {
            background: #ffd79b;
          }
        }
      }
      .readyCollect {
        .readyHead {
          background: #068d72;
        }
        .counts {
          span {
            background: #068d72;
          }
        }
      }
    }
  }
  