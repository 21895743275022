@font-face {
  font-family: "SFProText";
  src: url("../fonts/SFProText-Bold.woff2") format("woff2"),
    url("../fonts/SFProText-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProText";
  src: url("../fonts/SFProText-Medium.woff2") format("woff2"),
    url("../fonts/SFProText-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProText";
  src: url("../fonts/SFProText-Regular.woff2") format("woff2"),
    url("../fonts/SFProText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFProText";
  src: url("../fonts/SFProText-Semibold.woff2") format("woff2"),
    url("../fonts/SFProText-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "SFMono";
  font-weight: 600;
  src: url("../fonts/SFMonoSemibold.otf") format("opentype");
}
